import {computed, makeObservable, observable, reaction} from "mobx";
import {ClientAppStore} from "../../../../stores/ClientAppStore";
import {Nullable} from "../../../../ms-ui/types";
import {ClientPaymentHistoryItemViewModel, ClientViewModel, TariffViewModel} from "../../../../models/entities";
import {HttpClient} from "../../../../ms-ui/http";
import {CreatePaymentResponse} from "../../../../models/responses";
import {StoreBase} from "../../../../ms-ui/stores";
import ClientYandexMetrics from "../../../../metrics/ClientYandexMetrics";
import {ResponseBase} from "../../../../ms-ui/models/responses/ResponseBase";
import {clientAppSettings} from "../../../../clientAppSettings";

export class SubscribeTabStore extends StoreBase<ClientAppStore> {

    constructor(mainAppStore: ClientAppStore) {
        super(mainAppStore);

        makeObservable(this, {
            activePaymentHistoryItem: observable,
            tariffs: observable,
            client: observable,
            cancelSubscriptionDisabled: computed,
            cancelPaymentDisabled: computed,
            telegramLinkButtonDisabled: computed,
            isRefundPending: computed,
            isEmailConfirmed: computed,
            telegramLink: computed
        });

        reaction(
            () => this.appStore.user,
            user => this.client = this.appStore.user
        );
    }

    activePaymentHistoryItem: Nullable<ClientPaymentHistoryItemViewModel> = null;
    tariffs: TariffViewModel[] = [];
    client: Nullable<ClientViewModel> = null;

    componentDidMount = async () => {
        await super.componentDidMount();
        await this.init();
    }


    init = async () => {
        return this.execute(async () => {
            const httpClient = new HttpClient()
                .useAuthorization();

            const tariffsRequest = clientAppSettings.getService("getTariffs")();
            this.tariffs = await httpClient.execute<ResponseBase<TariffViewModel[]>, TariffViewModel[]>(tariffsRequest) ?? [];

            const getActivePaymentRequest = clientAppSettings.getService("getActivePayment")({userId: this.appStore.authStore.userId!});
            this.activePaymentHistoryItem = await httpClient.execute<ResponseBase<ClientPaymentHistoryItemViewModel>, ClientPaymentHistoryItemViewModel>(getActivePaymentRequest);

            this.client = this.appStore.user;
        });
    }

    onPay = async (tariff: TariffViewModel) => {
        return this.execute(async () => {
            ClientYandexMetrics.paymentClick();

            const request = clientAppSettings.getService("createPayment")({
                request: {
                    clientId: this.appStore.authStore.userId!,
                    tariff
                }
            })

            const confirmationToken = (await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase<CreatePaymentResponse>, CreatePaymentResponse>(request)).confirmationToken ?? null;

            if (confirmationToken === null) {
                throw new Error("Невозможно сформировать платеж");
            }

            const yooMoneyCheckoutWidget = new YooMoneyCheckoutWidget({
                confirmation_token: confirmationToken,
                customization: {
                    modal: true
                },
                error_callback: ({error}) => {
                    console.log(error);
                }
            });

            yooMoneyCheckoutWidget.on("modal_close", () => {
                yooMoneyCheckoutWidget.destroy();
            });

            yooMoneyCheckoutWidget.on("complete", (callbackStatus: CallbackStatus) => {
                yooMoneyCheckoutWidget.destroy();
                console.log(callbackStatus.status);

                if (callbackStatus.status === "success") {
                    ClientYandexMetrics.paymentSuccess();
                    // TODO: вопрос, а нужно ли перезагружать, или просто запросить текущую активную сессию?
                    this.appStore.success = "Оплата выполнена успешно!";
                    this.init();
                } else {
                    this.appStore.error = new Error("Оплата выполнена с ошибкой!");
                }
            });

            //Отображение платежной формы в контейнере
            yooMoneyCheckoutWidget.render();
        })
    }

    onCancelSubscription = () => {
        return this.execute(async () => {
            const request = clientAppSettings.getService("cancelSubscription")();
            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.init();
            this.appStore.success = "Отмена автоматического списания денежных средств выполнена успешно!";
        })
    }

    onEnableSubscription = () => {
        return this.execute(async () => {
            const request = clientAppSettings.getService("enableSubscription")();
            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.init();
            this.appStore.success = "Включение автоматического списания денежных средств выполнено успешно!";
        })
    }

    onCancelPayment = () => {
        return this.execute(async () => {
            const request = clientAppSettings.getService("cancelPayment")();
            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.init();
            this.appStore.success = "Отмена подписки выполнена успешно!";
        })
    }

    get cancelSubscriptionDisabled() {
        if (this.activePaymentHistoryItem === null) {
            return true;
        }

        return !this.activePaymentHistoryItem.nonAcceptancePaymentEnabled || this.isRefundPending || !this.isEmailConfirmed;
    }

    get cancelPaymentDisabled() {
        if (this.activePaymentHistoryItem === null) {
            return true;
        }

        return this.isRefundPending || !this.isEmailConfirmed;
    }

    get telegramLinkButtonDisabled() {
        return this.isRefundPending || !this.isEmailConfirmed;
    }

    get isRefundPending() {
        return this.activePaymentHistoryItem?.isRefundPending ?? false;
    }

    get isEmailConfirmed() {
        return this.client?.email === this.client?.emailConfirmed;
    }

    get telegramLink() {
        return `http://telegram.me/${this.client!.clientBotSystemName}?start=${this.client!.telegramInitId}`;
    }
}
