import {Box, MenuItem, TextField} from "@mui/material";
import {useMemo} from "react";

import {ProfileTabStore} from "./stores/ProfileTabStore";
import {observer} from "mobx-react";
import {useClientAppStore} from "../../hooks/useClientAppStore";
import {EmailConfirmEditor} from "../../../ms-ui/ui/components";
import {Sex} from "../../../ms-ui/commons";
import {EnhancedContainedButton, OutlinedTextField, OutlinedEnhancedSelect} from "../../../ms-ui/ui/controls";
import {AvatarEditor} from "../../../ms-ui/ui/components/avatarEditor/AvatarEditor";

export const ProfileTab = observer(() => {
    const clientAppStore = useClientAppStore();
    const store = useMemo(() => new ProfileTabStore(clientAppStore), []);

    return <Box
        sx={{
            p: 4,
            "& > *:first-of-type": {
                mb: 10
            },
            "& > *:not(:first-of-type)": {
                mb: 1
            }
        }}
    >
        <EmailConfirmEditor
            email={store.email}
            emailConfirmed={store.emailConfirmed}
            emailToBeConfirmed={store.emailToBeConfirmed}
            onChangeEmail={store.changeEmailHandler}
            onCancelChangeEmail={store.cancelChangeEmailHandler}
            onConfirmationEmailResend={store.confirmationEmailResendHandler}
        />
        <Box sx={{display: "flex", justifyContent: "center"}}>
            <AvatarEditor
                src={store.avatar}
                onChange={src => store.avatar = src}
            />
        </Box>
        <OutlinedTextField
            label="Никнейм"
            value={store.nickName}
            onChange={value => store.nickName = value}
            fullWidth
        />
        <OutlinedEnhancedSelect
            label="Пол"
            value={store.sex}
            onChange={value => store.sex = value}
            fullWidth
        >
            <MenuItem value={Sex.None}>Не выбран</MenuItem>
            <MenuItem value={Sex.Female}>Женщина</MenuItem>
            <MenuItem value={Sex.Male}>Мужчина</MenuItem>
        </OutlinedEnhancedSelect>
        <TextField
            label="Возраст"
            variant="outlined"
            fullWidth
            value={store.age}
            onChange={({target: {value}}) => store.age = value}
            size="small"
            type="number"
            inputProps={{min: 0, max: 100}}
        />
        <EnhancedContainedButton
            color="primary"
            onClick={store.editProfile}
        >
            Сохранить
        </EnhancedContainedButton>
    </Box>;
});