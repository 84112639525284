import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {TariffViewModel} from "../../../models/entities";

interface SubscribeCardProps {
    tariff: TariffViewModel;
    onPay: (tariff: TariffViewModel) => void;
    isPaidTariff: boolean;
    hasAnyPaidTariff: boolean;
    disabled?: boolean;
}

export const SubscribeCard = (props: SubscribeCardProps) => {

    const currencySymbol = props.tariff.currency.symbol ?? props.tariff.currency.shortName;

    return <Box mt={2}>
        <Grid container spacing={1}>
            <Grid item xs={8}>
                <Typography color="primary" variant="h6">«{props.tariff.name}»</Typography>
                {props.tariff.description && <Typography
                    variant="body2"
                    color="primary"
                    sx={{
                        whiteSpace: "pre-wrap"
                    }}
                >
                    {props.tariff.description}
                </Typography>}
            </Grid>
            <Grid item xs={4}>
                <Stack
                    sx={{
                        height: "100%"
                    }}
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Typography
                        variant="h4"
                        color="primary"
                    >
                        {props.tariff.value} {currencySymbol} в неделю
                    </Typography>
                    {!props.isPaidTariff && <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => props.onPay(props.tariff)}
                        disabled={props.disabled}
                        fullWidth
                    >
                        {props.hasAnyPaidTariff ? "Перейти" : "Подписаться"}
                    </Button>}
                </Stack>
            </Grid>
        </Grid>
    </Box>
}