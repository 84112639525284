import {ClientRegistrationRequest, CreatePaymentRequest} from "./models/requests";
import {ClientViewModel} from "./models/entities";
import {AppRoutes} from "./ms-ui/settings/types/AppRoutes";
import {AppServices} from "./ms-ui/settings/types/AppServices";
import {
    AppSettingsEmptyGetConfig,
    AppSettingsGetConfig,
    AppSettingsPostConfig
} from "./ms-ui/settings/types/AppSettingsConfig";
import appSettings from "./ms-ui/appSettings";

export interface ClientAppRoutes extends AppRoutes {
    settings: string;
}

export interface ClientAppServices extends AppServices {
    registration: AppSettingsPostConfig<ClientRegistrationRequest>;
    getUserById: AppSettingsGetConfig<"userId">;
    editUser: AppSettingsPostConfig<ClientViewModel>;
    createPayment: AppSettingsPostConfig<CreatePaymentRequest>;
    getActivePayment: AppSettingsGetConfig<"userId">;
    getTariffs: AppSettingsEmptyGetConfig;
    cancelSubscription: AppSettingsEmptyGetConfig;
    enableSubscription: AppSettingsEmptyGetConfig;
    cancelPayment: AppSettingsEmptyGetConfig;
}

const clientAppSettings = appSettings.castTo<ClientAppRoutes, ClientAppServices>();

clientAppSettings
    .addRoute("login", "/login")
    .addRoute("passwordRecovery", "/recovery-password")
    .addRoute("registration", "/registration")
    .addRoute("confirmEmail", "/confirm-email")
    .addRoute("setPassword", "/set-password")
    .addRoute("settings", "settings")
    .addService("login", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/auth/client-login`,
        data: request,
        withCredentials: true
    }))
    .addService("logout", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/auth/client-logout`,
        withCredentials: true
    }))
    .addService("updateAccessToken", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/auth/client-update-access-token`,
        data: request,
        withCredentials: true
    }))
    .addService("getUserById", request => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/${request.userId}`
    }))
    .addService("registration", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/registration`,
        data: request
    }))
    .addService("changeEmail", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/change-email`,
        data: request
    }))
    .addService("cancelChangeEmail", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/cancel-change-email`,
        data: request
    }))
    .addService("confirmEmail", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/confirm-email`,
        data: request
    }))
    .addService("confirmationEmailResend", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/confirmation-email-resend`,
        data: request
    }))
    .addService("editUser", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/edit`,
        data: request
    }))
    .addService("getUserId", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/get-id`
    }))
    .addService("changePassword", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/change-password`,
        data: request
    }))
    .addService("setPassword", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/set-password`,
        data: request
    }))
    .addService("passwordRecovery", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/recovery-password`,
        data: request
    }))
    .addService("removeProfile", () => ({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}/api/clientProfile/remove`
    }))
    .addService("createPayment", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/clientPayments/create`,
        data: request
    }))
    .addService("getActivePayment", request => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/clientPayments/active/${request.userId}`
    }))
    .addService("cancelSubscription", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/clientPayments/cancel-subscription`
    }))
    .addService("enableSubscription", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/clientPayments/enable-subscription`
    }))
    .addService("cancelPayment", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/clientPayments/cancel-payment`
    }))
    .addService("getTariffs", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/tariffs/list`
    }))
    .addService("getVersion", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/software-components/client-ui`
    }))
    .freeze();

export {clientAppSettings};