import {YandexMetrics} from "../ms-ui/metrics";

class ClientYandexMetrics extends YandexMetrics {
    /**
     * отправка формы регистрации
     */
    readonly sendRegistrationForm = () => this.send("send_registration_form");

    /**
     * переход на оплату
     */
    readonly paymentClick = () => this.send("payment_click");

    /**
     * успешная оплата
     */
    readonly paymentSuccess = () => this.send("payment_success");
}

export default new ClientYandexMetrics(process.env.REACT_APP_YM_COUNTER_ID as unknown as number);