import {EnhancedButton} from "../controls";
import {useNavigate} from "react-router-dom";
import appSettings from "../../appSettings";

export const RegistrationButton = () => {
    const navigate = useNavigate();

    const onRegistrationClick = () => {
        navigate(appSettings.getRoute("registration"));
    }

    return <EnhancedButton
        color="primary"
        fullWidth
        onClick={onRegistrationClick}
    >
        Регистрация
    </EnhancedButton>;
}