import {Box, Typography} from "@mui/material";

export const WarningAboutTelegramBot = () => {
    return <Box
        sx={{
            backgroundColor: "rgb(255, 244, 229)",
            mb: 1,
            borderRadius: "4px",
            color: "rgb(102, 60, 0)",
            padding: "6px 16px"
        }}
    >
        <Typography
            align="center"
            variant="body2"
        >
            Для начала общения с терапевтом переходите в Телеграм только по кнопке на вкладке Подписка.<br/>
            После первого перехода вы привяжетесь к боту и далее можно будет общаться напрямую из мессенджера.
        </Typography>
    </Box>
}