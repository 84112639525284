import {makeObservable, observable, reaction} from "mobx";
import {StoreBase} from "../../../../ms-ui/stores";
import {ClientAppStore} from "../../../../stores/ClientAppStore";
import {Nullable} from "../../../../ms-ui/types";
import {ClientViewModel} from "../../../../models/entities";
import {Sex} from "../../../../ms-ui/commons";
import {HttpClient} from "../../../../ms-ui/http";
import {ResponseBase} from "../../../../ms-ui/models/responses/ResponseBase";
import {clientAppSettings} from "../../../../clientAppSettings";

export class ProfileTabStore extends StoreBase<ClientAppStore> {

    private client: Nullable<ClientViewModel> = this.appStore.user;

    constructor(mainAppStore: ClientAppStore) {
        super(mainAppStore);

        makeObservable<ProfileTabStore, "client">(this, {
            client: observable
        });

        reaction(
            () => this.appStore.user,
            user => this.client = user
        );
    }

    editProfile = async () => {
        return this.execute(async () => {
            const request = clientAppSettings.getService("editUser")({request: this.client!});
            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.appStore.getUser();
            this.appStore.success = "Данные успешно сохранены."
        });
    }

    changeEmailHandler = async (email: string, emailToBeConfirmed: Nullable<string>) => {
        return this.execute(async () => {

            const request = clientAppSettings.getService("changeEmail")({
                request: {
                    userId: this.client!.id!,
                    email,
                    emailToBeConfirmed: emailToBeConfirmed!
                }
            });

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.appStore.getUser();
            this.appStore.success = "E-mail был изменен, письмо с подтверждением было отправлено вам на почту.";
        });
    }

    cancelChangeEmailHandler = async () => {
        return this.execute(async () => {
            const request = clientAppSettings.getService("cancelChangeEmail")({
                request: {
                    userId: this.client!.id!
                }
            });

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.appStore.getUser();
            this.appStore.success = "Смена E-mail была отменена.";
        });
    }

    confirmationEmailResendHandler = async () => {
        return this.execute(async () => {
            const request = clientAppSettings.getService("confirmationEmailResend")({
                request: {
                    userId: this.client!.id!
                }
            });

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.appStore.getUser();
            this.appStore.success = "Письмо с подверждением было повторно отправлено вам на почту.";
        });
    }

    get avatar() {
        return this.client?.avatar ?? null;
    }

    set avatar(value: Nullable<string>) {
        this.client!.avatar = value;
    }

    get email() {
        return this.client?.email ?? "";
    }

    set email(value: string) {
        this.client!.email = value.trim() === "" ? null : value;
    }

    get nickName() {
        return this.client?.nickName ?? "";
    }

    set nickName(value: Nullable<string>) {
        this.client!.nickName = value !== null ? value.trim() : null;
    }

    get age() {
        return this.client?.age?.toString() ?? "";
    }

    set age(value: string) {
        this.client!.age = value.trim() === "" ? null : parseInt(value, 10);
    }

    get sex() {
        return this.client?.sex ?? Sex.None;
    }

    set sex(value: Sex) {
        this.client!.sex = value;
    }

    get emailConfirmed() {
        return this.client?.emailConfirmed ?? null;
    }

    get emailToBeConfirmed() {
        return this.client?.emailToBeConfirmed ?? null;
    }
}