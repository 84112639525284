import {Navigate, Route} from "react-router-dom";
import {ThemeProvider} from '@mui/material/styles';
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import ru from "dayjs/locale/ru";
import {AuthRoute} from "./ms-ui/ui/components/authentificate/AuthRoute";
import {MainApp} from "./ui/components/MainApp/MainApp";
import {Registration} from "./ui/components/Registration/Registration";
import {Settings} from "./ui/components/Settings";
import {ClientAppStore} from "./stores/ClientAppStore";
import {useEffect, useMemo} from "react";
import {MindsafeTheme} from "./ms-ui/theme";
import {MainAppProvider} from "./ms-ui/ui/components/mainApp";
import {Login} from "./ms-ui/ui/components/login/Login";
import {RegistrationButton} from "./ms-ui/ui/navigation";
import {AuthStore} from "./ms-ui/ui/components/authentificate/AuthStore";
import {Typography} from "@mui/material";
import appSettings from "./ms-ui/appSettings";
import {clientAppSettings} from "./clientAppSettings";

dayjs.locale(ru);
dayjs.extend(localizedFormat);

export const App = () => {
    const clientAppStore = useMemo(() => new ClientAppStore(AuthStore), []);

    useEffect(() => {
        clientAppStore.init().then();
    }, []);

    return <ThemeProvider theme={MindsafeTheme}>
        <MainAppProvider store={clientAppStore}>
            <Route path={appSettings.getRoute("login")}
                   element={<Login
                       top={<Typography color="primary" variant="h5" sx={{mb: 4}}>вход для клиента</Typography>}
                       bottom={<RegistrationButton/>}
                   />}
            />
            <Route
                path={appSettings.getRoute("registration")}
                element={<Registration/>}
            />
            <Route
                path="/"
                element={
                    <AuthRoute>
                        <MainApp/>
                    </AuthRoute>}>
                <Route index element={<Navigate to={`${clientAppSettings.getRoute("settings")}?idx=0`}/>}/>
                <Route path={clientAppSettings.getRoute("settings")} element={
                    <AuthRoute>
                        <Settings/>
                    </AuthRoute>
                }/>
            </Route>
        </MainAppProvider>
    </ThemeProvider>
}