import {ProfileTab} from "./ProfileTab";
import {SubscribeTab} from "./SubscribeTab";
import Tabs from "../../../ms-ui/ui/components/tabs/Tabs";
import {AboutTab, SecurityTab} from "../../../ms-ui/ui/components";

export const Settings = () => {
    return <Tabs>
        <Tabs.Item label="Подписка" component={<SubscribeTab/>}/>
        <Tabs.Item label="Профиль" component={<ProfileTab/>}/>
        <Tabs.Item label="Безопасность" component={<SecurityTab/>}/>
        <Tabs.Item label="О компании" component={<AboutTab/>}/>
    </Tabs>;
}