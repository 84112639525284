export class YandexMetrics {

    constructor(private counterId: number) {
    }

    protected send = (target: string) => {
        try {
            ym(this.counterId, "reachGoal", target);
        } catch {

        }
    }
}