import {makeObservable, observable, reaction} from "mobx";
import {Nullable} from "../ms-ui/types";
import {ClientViewModel} from "../models/entities";
import {HttpClient} from "../ms-ui/http";
import {AuthState} from "../ms-ui/ui/components/authentificate/types/AuthState";
import {MainAppStore} from "../ms-ui/ui/components/mainApp";
import {AuthStore} from "../ms-ui/ui/components/authentificate/AuthStore";
import axios from "axios";
import {ResponseBase} from "../ms-ui/models/responses/ResponseBase";
import {clientAppSettings} from "../clientAppSettings";

export class ClientAppStore extends MainAppStore {
    user: Nullable<ClientViewModel> = null;

    constructor(authStoreCtr: { new(mainAppStore: MainAppStore): AuthStore }) {
        super(authStoreCtr);

        makeObservable(this, {
            user: observable
        });

        reaction(
            () => this.authStore.userId,
            userId => this.getUser()
        );
    }

    getUser = async () => {
        try {
            if (this.authStore.userId == null) {
                return;
            }

            const getUserByIdRequest = clientAppSettings
                .getService("getUserById")({userId: this.authStore.userId});
            this.user = await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase<ClientViewModel>, ClientViewModel>(getUserByIdRequest);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                this.error = error.cause ?? new Error(error.message);
            } else if (error instanceof Error) {
                this.error = error;
            }
        }
    }

    onLogout = async () => {
        await this.authStore.onLogout(AuthState.LoggedOut);
    }
}