import {Alert, AlertTitle, Box, Button, Link, Stack, Typography} from "@mui/material";
import {useMemo} from "react";

import {SubscribeCard} from "./SubscribeCard";
import {SubscribeTabStore} from "./stores/SubscribeTabStore";
import dayjs from "dayjs";
import {observer} from "mobx-react";
import TelegramIcon from "@mui/icons-material/Telegram";
import {useClientAppStore} from "../../hooks/useClientAppStore";
import {useStoreLifeCycle} from "../../../ms-ui/stores";
import {OverlayFlexContainer} from "../../../ms-ui/ui/components/OverlayFlexContainer";


export const SubscribeTab = observer(() => {
    const clientAppStore = useClientAppStore();
    const store = useMemo(() => new SubscribeTabStore(clientAppStore), []);

    useStoreLifeCycle(store);

    return <OverlayFlexContainer>
        <Stack
            spacing={10}
            sx={{
                p: 4,
                height: "100%"
            }}>
            <Stack spacing={1}>
                {!store.isEmailConfirmed && <Alert color="warning">
                    Для работы подтвердите ваш E-mail.
                </Alert>}
                <Typography variant="h5" mb={2}>Подписка</Typography>
                {store.activePaymentHistoryItem !== null
                    ? <>
                        <Typography color="primary" mb={2}>Ваш тариф «{store.activePaymentHistoryItem.tariff.name}»,
                            действует
                            до {dayjs(store.activePaymentHistoryItem.endDateTime).format("DD.MM.YYYY HH:mm")}</Typography>
                        {store.isRefundPending && <Alert severity="warning" icon={false}>
                            <AlertTitle>Ожидаем отмены вашего платежа.</AlertTitle>
                            До получения информации от платежной системы вы не сможете подписаться снова.
                        </Alert>}
                        <Stack alignItems="center">
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => window.location.assign(store.telegramLink)}
                                startIcon={<TelegramIcon fontSize="small"/>}
                                disabled={store.telegramLinkButtonDisabled}
                            >
                                Перейти в чат с психологом и работать
                            </Button>
                        </Stack>
                    </>
                    : <Typography>У вас нет активной подписки</Typography>
                }
            </Stack>
            <Stack spacing={1}>
                <Typography variant="h5" mb={2}>Тарифые планы</Typography>
                <Alert severity="warning" icon={false}>
                    <AlertTitle>Проблемы при оплате?</AlertTitle>
                    Некоторые из наших клиентов столкнулись с ошибкой при оплате с заголовком “Технический сбой”. В этом
                    случае попробуйте приостановить работу антивируса на время оплаты.
                </Alert>
                <Stack spacing={4}>
                    {store.tariffs.map(tariff => <SubscribeCard
                        key={tariff.id}
                        tariff={tariff}
                        onPay={store.onPay}
                        isPaidTariff={tariff.systemName === store.activePaymentHistoryItem?.tariff.systemName}
                        hasAnyPaidTariff={store.activePaymentHistoryItem !== null}
                        disabled={store.isRefundPending || !store.isEmailConfirmed}
                    />)}
                </Stack>
            </Stack>
            <Box>
                {store.activePaymentHistoryItem !== null
                    ? <>
                        <Typography variant="h5" mb={2} mt={24}>Управление подпиской</Typography>
                        {store.activePaymentHistoryItem.nonAcceptancePaymentEnabled
                            ? <><Link
                                component="button"
                                color="primary"
                                variant="body2"
                                onClick={store.onCancelSubscription}
                                disabled={store.cancelSubscriptionDisabled}
                            >
                                Прекратить авто продление
                            </Link><Typography variant="body2" component="span"> , но доработать до конца оплаченного
                                периода.</Typography></>
                            : <><Typography variant="body2" component="span">Автоматическое продление
                                отключенo.</Typography><Link
                                component="button"
                                color="primary"
                                variant="body2"
                                onClick={store.onEnableSubscription}
                            >
                                Включить авто продление.
                            </Link>
                            </>}
                        <br/>
                        <><Link
                            component="button"
                            color="primary"
                            variant="body2"
                            onClick={store.onCancelPayment}
                            disabled={store.cancelPaymentDisabled}
                        >
                            Прекратить подписку
                        </Link><Typography variant="body2" component="span"> и закончить прямо сейчас</Typography>.
                        </>
                    </>
                    : <></>}
            </Box>
        </Stack>
    </OverlayFlexContainer>
});
