import {CssBaseline} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FaceIcon from "@mui/icons-material/Face";
import {observer} from "mobx-react";
import {MainAppBar, MainAppContainer, MainAppOutlet} from "../../../ms-ui/ui/components/mainApp";
import {ButtonMenu} from "../../../ms-ui/ui/navigation";
import {useClientAppStore} from "../../hooks/useClientAppStore";
import {UserAvatar} from "../../../ms-ui/ui/components";
import {MindSafeLogo} from "../../../ms-ui/ui/surfaces";
import {WarningAboutTelegramBot} from "../WarningAboutTelegramBot";
import {clientAppSettings} from "../../../clientAppSettings";

export const MainApp = observer(() => {
    const clientAppStore = useClientAppStore();

    const navigate = useNavigate();
    const menuItemHandler = (to: string) => {
        navigate(to);
    }

    return <MainAppContainer>
        <CssBaseline/>
        <MainAppBar>
            <MindSafeLogo/>
            <ButtonMenu icon={<UserAvatar
                src={clientAppStore.user?.avatar ?? null}
                alt={clientAppStore.user?.nickName}/>}>
                <ButtonMenu.Item
                    icon={<FaceIcon fontSize="small"/>}
                    primaryText={clientAppStore.user?.nickName}
                    secondaryText={clientAppStore.user?.email}
                    onClick={() => menuItemHandler(`${clientAppSettings.getRoute("settings")}?idx=1`)}
                />
                <ButtonMenu.Divider/>
                <ButtonMenu.Item
                    icon={<ExitToAppIcon fontSize="small"/>}
                    primaryText="Выход"
                    onClick={clientAppStore.onLogout}
                />
            </ButtonMenu>
        </MainAppBar>
        <WarningAboutTelegramBot/>
        <MainAppOutlet/>
    </MainAppContainer>
});