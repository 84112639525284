import {computed, makeObservable, observable} from "mobx";
import {Sex} from "../../../ms-ui/commons";
import {RegistrationClientViewModel} from "../../../models/entities/RegistrationClientViewModel";
import {AuthHelper} from "../../../ms-ui/helpers";
import {ClientAppStore} from "../../../stores/ClientAppStore";
import {HttpClient} from "../../../ms-ui/http";
import {StoreBase} from "../../../ms-ui/stores";
import ClientYandexMetrics from "../../../metrics/ClientYandexMetrics";
import {ResponseBase} from "../../../ms-ui/models/responses/ResponseBase";
import {clientAppSettings} from "../../../clientAppSettings";

export class RegistrationStore extends StoreBase<ClientAppStore> {
    client: RegistrationClientViewModel;

    constructor(mainAppStore: ClientAppStore, private _postRegistrationAction: VoidFunction) {
        super(mainAppStore);

        this.client = {
            id: null,
            nickName: null,
            avatar: null,
            about: null,
            sex: Sex.None,
            age: null,
            email: null,
            userAgreement: false,
            userDataPolicy: false,
            telegramInitId: null,
            password: null,
            passwordConfirmation: null,
            emailConfirmed: null,
            emailToBeConfirmed: null,
            clientBotSystemName: null
        };

        makeObservable(this, {
            client: observable,
            isPasswordInvalid: computed,
            isPasswordConfirmationInvalid: computed,
            registrationBtnDisabled: computed
        });
    }

    registration = async () => {
        return this.execute(async () => {
            ClientYandexMetrics.sendRegistrationForm();

            const request = clientAppSettings.getService("registration")({request: {client: this.client}});
            await new HttpClient()
                .execute<ResponseBase>(request);

            await this.appStore.authStore.onLogin(this.client.email!, this.client.password!);
            this._postRegistrationAction();
        });
    }

    get isPasswordInvalid() {
        if (this.client.password === null && this.client.passwordConfirmation === null) {
            return null;
        }

        if (this.client.password === null) {
            return "Не может быть пустым.";
        }

        if (!AuthHelper.validatePassword(this.client.password ?? "")) {
            return "Не соответствует требованиям политики безопасности формирования пароля, пароль должен содержать прописные и строчные буквы, хотя бы одну цифру и быть в длинну от 8 символов.";
        }

        return null;
    }

    get isPasswordConfirmationInvalid() {
        if (this.client.password === null && this.client.passwordConfirmation === null) {
            return null;
        }

        if (this.client.password !== this.client.passwordConfirmation) {
            return "Новый пароль и повтор не совпадают.";
        }

        return null;
    }

    get registrationBtnDisabled() {
        return this.client.email == null
            || this.client.nickName == null
            || this.client.password == null
            || this.client.passwordConfirmation == null
            || !this.client.userAgreement
            || !this.client.userDataPolicy
            || this.isPasswordInvalid !== null
            || this.isPasswordConfirmationInvalid !== null;
    }
}