import {Button, MenuItem} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {RegistrationStore} from "./RegistrationStore";
import {observer} from "mobx-react";
import {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {Sex} from "../../../ms-ui/commons";
import {useClientAppStore} from "../../hooks/useClientAppStore";
import {LoginHelper} from "../../../ms-ui/helpers";
import {BorderedBox} from "../../../ms-ui/ui/surfaces";
import {OutlinedEnhancedSelect, OutlinedTextField} from "../../../ms-ui/ui/controls";
import {OutlinedPasswordTextField} from "../../../ms-ui/ui/controls/OutlinedPasswordTextField";
import {EnhancedCheckbox} from "../../../ms-ui/ui/controls/EnhancedCheckbox";
import {MindSafeLicenseText, MindSafeUserDataPolicyText} from "../../../ms-ui/ui/components/information";
import {AvatarEditor} from "../../../ms-ui/ui/components/avatarEditor/AvatarEditor";

export const Registration = observer(() => {
    const clientAppStore = useClientAppStore();

    const postRegistrationAction = () => {
        LoginHelper.setRememberMe(true);
        LoginHelper.setLogin(store.client.email);
        LoginHelper.setPassword(store.client.password);
        navigate("/");
    }

    const store = useMemo(() => new RegistrationStore(clientAppStore, postRegistrationAction), []);
    const navigate = useNavigate();

    return <BorderedBox>
        <AvatarEditor
            src={store.client.avatar}
            onChange={src => store.client.avatar = src}
        />
        <OutlinedTextField
            label="E-mail"
            placeholder="__________@_____.__"
            value={store.client.email ?? ""}
            onChange={value => store.client.email = value === "" ? null : value}
            fullWidth
            type="email"

        />
        <OutlinedTextField
            label="Псевдоним"
            value={store.client.nickName ?? ""}
            onChange={value => store.client.nickName = value === "" ? null : value}
            fullWidth
        />
        <OutlinedEnhancedSelect
            label="Пол"
            value={store.client.sex}
            onChange={value => store.client.sex = value}
            fullWidth
            onClear={() => store.client.sex = Sex.None}
        >
            <MenuItem value={Sex.None}>Не выбран</MenuItem>
            <MenuItem value={Sex.Female}>Женщина</MenuItem>
            <MenuItem value={Sex.Male}>Мужчина</MenuItem>
        </OutlinedEnhancedSelect>
        <OutlinedPasswordTextField
            label="Пароль"
            value={store.client.password}
            onChange={value => store.client.password = value}
            error={store.isPasswordInvalid}
            fullWidth
        />
        <OutlinedPasswordTextField
            label="Подтверждение пароля"
            value={store.client.passwordConfirmation}
            onChange={value => store.client.passwordConfirmation = value}
            error={store.isPasswordConfirmationInvalid}
            fullWidth
        />
        <EnhancedCheckbox
            label={<MindSafeLicenseText/>}
            checked={store.client.userAgreement}
            onChange={checked => store.client.userAgreement = checked}
        />
        <EnhancedCheckbox
            label={<MindSafeUserDataPolicyText/>}
            checked={store.client.userDataPolicy}
            onChange={checked => store.client.userDataPolicy = checked}
        />
        <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowForwardIcon fontSize="small"/>}
            onClick={() => store.registration()}
            fullWidth
            disabled={store.registrationBtnDisabled}
        >
            Зарегистрироваться
        </Button>
    </BorderedBox>;
})